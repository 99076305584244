exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-equityrelease-js": () => import("./../../../src/pages/equityrelease.js" /* webpackChunkName: "component---src-pages-equityrelease-js" */),
  "component---src-pages-financialplanning-js": () => import("./../../../src/pages/financialplanning.js" /* webpackChunkName: "component---src-pages-financialplanning-js" */),
  "component---src-pages-freelancers-js": () => import("./../../../src/pages/freelancers.js" /* webpackChunkName: "component---src-pages-freelancers-js" */),
  "component---src-pages-generalinsurance-js": () => import("./../../../src/pages/generalinsurance.js" /* webpackChunkName: "component---src-pages-generalinsurance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meettheteam-js": () => import("./../../../src/pages/meettheteam.js" /* webpackChunkName: "component---src-pages-meettheteam-js" */),
  "component---src-pages-mortgages-js": () => import("./../../../src/pages/mortgages.js" /* webpackChunkName: "component---src-pages-mortgages-js" */),
  "component---src-pages-pensions-js": () => import("./../../../src/pages/pensions.js" /* webpackChunkName: "component---src-pages-pensions-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-protection-js": () => import("./../../../src/pages/protection.js" /* webpackChunkName: "component---src-pages-protection-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-wealthmanagement-js": () => import("./../../../src/pages/wealthmanagement.js" /* webpackChunkName: "component---src-pages-wealthmanagement-js" */)
}

